import axios from "axios";

export default {
  getConfiguracaoGeral() {
    return axios.get("/v1/configuracao-geral");
  },
  setConfiguracaoGeral(payload) {
    return axios.put("/v1/configuracao-geral", payload);
  },
  uploadBanner(formData) {
    return axios.post(`/v1/configuracao-geral/banner-url`, formData, {});
  },
  uploadBannerApp(formData) {
    return axios.post(`/v1/configuracao-geral/banner-app-url`, formData, {});
  },
  uploadBannerDesconto(formData) {
    return axios.post(
      `/v1/configuracao-geral/banner-desconto-url`,
      formData,
      {}
    );
  },
  uploadBannerIngresso(formData) {
    return axios.post(
      `/v1/configuracao-geral/banner-ingresso-url`,
      formData,
      {}
    );
  },
  uploadLogo(formData) {
    return axios.post(`/v1/configuracao-geral/banner-logo-url`, formData, {});
  },
  deleteLogo() {
    return axios.delete(`/v1/configuracao-geral/banner-logo-url`);
  },
  deleteBanner() {
    return axios.delete(`/v1/configuracao-geral/banner-url`);
  },
  deleteBannerApp() {
    return axios.delete(`/v1/configuracao-geral/banner-app-url`);
  },
  uploadLogoApp(formData) {
    return axios.post(`/v1/configuracao-geral/logo-url`, formData, {});
  }
};
