export default [
  {
    path: "/listagem-produtos",
    component: () => import("@/views/produtos/listagemProdutos.vue"),
  },
  {
    path: "/cadastrar-produtos",
    component: () => import("@/views/produtos/cadastroProdutos.vue"),
  },
  {
    path: "/cadastrar-cupom",
    component: () => import("@/views/produtos/cadastroCupom.vue"),
  },
  {
    path: "/cadastrar-ingresso",
    component: () => import("@/views/produtos/cadastroIngresso.vue"),
  },
];
