import axios from "axios";

export default {
    cadastroLocalCategoria(_payload){
        return axios.post('/v1/local/categoria', {
            descricao: _payload.descricao,
            localId: _payload.localId
        })
    },

    cadastroLocalPrecoCategoria(_payload){
        return axios.post('/v1/local/categoria/preco', {
            descricao: _payload.descricao,
            localCategoriaId: _payload.localCategoriaId,
            sigla: _payload.sigla,
            valor: _payload.valor,
            id: _payload.id
        })
    },

    getLocalCategoriaDetalhe(_id){
        return axios.get(`/v1/local/categoria/${_id}`)
    },

    getLocalCategoriaDetalheNotNull(_id){
        return axios.get(`/v1/local/categoria/notnull/${_id}`)
    },

    getLocalSituacoes(){
        return axios.get(`/v1/local/item/situacao`)
    },

    getLocalPrecoCategoriaDetalhe(_id){
        return axios.get(`/v1/local/categoria/preco/${_id}`)
    },

    getLocalCategoriaPreco(_id){
        return axios.get(`/v1/local/categoria/preco/${_id}`)
    },

    editLocal(_payload){
        return axios.post('/v1/local/categoria', {
            descricao: _payload.descricao,
            id: _payload.id
        })
    },

    deleteLocal(_id){
        return axios.delete(`/v1/local/${_id}`)
    },

    deletePreco(_id){
        return axios.delete(`/v1/local/categoria/preco/${_id}`)
    },

    deleteCategoria(_id){
        return axios.delete(`/v1/local/categoria/${_id}`)
    },

}