import axios from "axios";

export default {
    getAllMidias(pageSize = 15, pageNo = 0, sortBy = "descricao"){
        return axios.post(`/v1/pacote/pagination`,{
            descending: false,
            pageSize,
            filtros:{},
            pageNo,
            sortBy,
          });
    },

    cadastrarPacote(payload){
        return axios.post('/v1/pacote', payload)
    },

    getMidiasInPacote(_id){
        return axios.get(`/v1/pacote/midia/${_id}`)
    },

    insertMidia(payload){
        return axios.post(`/v1/pacote/midia`, payload)
    },

    uploadMidiasPacote(formData){
        return axios.post('/v1/pacote/upload-midias', formData, {});
    },

    setCapaPacote(_id){
        return axios.post(`/v1/pacote/capa/${_id}`,)
    },

    getLiberados(){
        return axios.get('/v1/pacote/liberados')
    },

    definirPacote(_id){
        return axios.get(`/v1/pacote/definir/${_id}`)
    },

    deleteImages(_payload){
        const data = _payload
        return axios.delete('/v1/pacote/midia',{
           data
        })
    },


}