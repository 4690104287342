<template>
  <div>
    <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
    <div v-show="!loading">
      <span v-show="playerReady">
        <video-player
          class="vjs-big-play-centered"
          ref="videoPlayer"
          :options="playerOptions"
        />
      </span>
      <v-row v-if="!playerReady" no-gutters class="div-wait-video">
        <v-col align-self="center">
          <div>Video publicado<br />Aguardando disponibilizar.</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { VideoService } from "../../services";
import VideoPlayer from "vue-videojs7/src/components/VideoPlayer.vue";
export default {
  props: ["payload", "height", "width"],
  components: {
    VideoPlayer
  },
  data() {
    return {
      video: [],
      playerReady: false,
      loading: true,
      playerOptions: {
        width: this.width,
        height: this.height,
        autoplay: false,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
      }
    };
  },
  beforeMount() {
    this.getVideo();
  },
  methods: {
    loadVideo() {
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(this.video);
    },
    updateVideoContent(data) {
      this.video = {
        src: data.signedUrl,
        type: data.type,
        withCredentials: false
      };
      this.loadVideo();
    },
    getVideo() {
      VideoService.getVideoVod(this.payload.midiaUrl)
        .then(response => {
          this.playerReady = !response.data.is_processing;
          this.updateVideoContent(response.data);
        })
        .catch(() => {
          this.playerReady = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  }
};
</script>
