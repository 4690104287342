import axios from "axios";

export default {
  cadastroIngresso(payload) {
    return axios.post("/v1/ingresso", payload);
  },

  cadastrarItemIngresso(payload) {
    return axios.post("/v1/ingresso/item", payload);
  },

  getEventoIngresso(id) {
    return axios.get(`/v1/ingresso/pagination/item/${id}`);
  },

  getLocaisCliente(id) {
    return axios.get(`/v1/ingresso/locais/${id}`);
  },

  uploadMidia(payload) {
    return axios.post("/v1/ingresso/imagem/mapa", payload);
  },

  deleteMidia(_id) {
    return axios.delete(`/v1/ingresso/imagem/mapa/${_id}`);
  },

  deleteItem(id) {
    return axios.delete(`/v1/ingresso/item/${id}`);
  },

  getRelatorio(_id){
    return axios.post(`/v1/ingresso/detalhes`,{
      descending: true,
      filtros: {
        id: _id
      },
      pageNo: 0,
      pageSize: 0,    
      sortBy: "id"
    })
  },

  getCategoriasAtivas(_id){
    return axios.get(`/v1/ingresso/categorias-nao-esgotadas/item/${_id}`)
  },

  getRequisitosByInstalacao(){
    return axios.get(`/v1/ingresso/requisitos`)
  },

  getTaxaConveniencia(_id){
    return axios.get(`/v1/ingresso/taxa-conveniencia/${_id}`)
  },

  setTaxaConveniencia(payload){
    return axios.post("/v1/ingresso/taxa-conveniencia", payload);
  }

};
