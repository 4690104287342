export default {
  getToken(state) {
    return state.user.token;
  },
  getRole(state) {
    return state.user.roleLabel;
  },
  getAuthStatus(state) {
    return state.authStatus;
  },
  getUser(state) {
    return state.user;
  },
  getUserName(state) {
    return state.user.nome;
  }
};
