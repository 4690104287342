<template>
  <div>
    <div v-if="payload.isVod">
      <video-comp-vod :payload="payload" :height="height" :width="width" />
    </div>
    <div v-else>
      <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
      <div v-else>
        <span v-if="playerReady" v-html="conteudo"></span>
        <v-row v-else no-gutters class="div-wait-video">
          <v-col align-self="center">
            <div>Video publicado<br />Aguardando disponibilizar.</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import { VideoService } from "../../services";
import VideoCompVod from "./videoCompVod.vue";
export default {
  props: ["payload", "height", "width"],
  components: {
    VideoCompVod
  },
  data() {
    return {
      conteudo: "",
      urlVideo: "",
      widthScreen: 0,
      playerReady: false,
      loading: true
    };
  },
  beforeMount() {
    if (this.payload.isVod) {
      return;
    }
    this.getVideo();
  },
  methods: {
    updateVideoContent(data) {
      this.conteudo = data.html;
    },
    getVideo() {
      VideoService.getVideoVimeo(this.payload.midiaUrl, this.width, this.height)
        .then(response => {
          this.playerReady = true;
          this.updateVideoContent(response.data);
        })
        .catch(() => {
          this.playerReady = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>