import axios from "axios";

export default {

  getVideoVimeo(id, width, height) {
      return axios
          .get(
            `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}&width=${width}&height=${height}&controls=false`,
            {
              baseURL: "https://vimeo.com/api/oembed.json"
            }
          );
  },
  getVideoVod(id) {
    return axios
          .get(`/v1/video/${id}`);
  }
};
