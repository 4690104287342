import axios from "axios";

export default {
  situacoes() {
    return {
      PENDENTE: "Pendente",
      PROGRAMADO: "Programado",
      CONCLUIDO: "Concluído",
      AGUARDANDO: "Aguardando programação",
      CANCELADO: "Cancelado",
      EXCLUIDO: "Excluído",
      APROVADO: "Aprovado para publicação",
      PUBLICADO: "Publicado"
    };
  },

  defaultPost() {
    return {
      id: null,
      legenda: "",
      clienteId: null,
      situacaoPublicacao: "PENDENTE",
      cidadeId: null,
      selectedAssuntos: [],
      dhInicioPublicacao: "",
      dhFimPublicacao: "",
      midiaUrl: "",
      tpMidia: null,
      hashtags: "",
      tipoPublicacao: "A",
      selectedBairros: [],
      planoId: null,
      valor: null,
      link: "",
      isNotificado: false,
      descricaoNotificacao: "",
      regiaoInteresseId: null
    };
  },

  salvar(post) {
    if (post.id && post.id != 0) {
      return axios.post(`/v1/post/${post.id}`, post);
    }
    return axios.post(`/v1/post`, post);
  },

  uploadMidia(id, formData) {
    if (id && id != 0) {
      return axios.post(`/v1/post/${Number(id)}/upload-midia`, formData, {});
    }
    return axios.post(`/v1/post/upload-midia-sem-post`, formData, {});
  },

  getPostById(id) {
    return axios.get(`/v1/post/${id}`);
  },

  deleteMidia(id) {
    return axios.delete(`/v1/post/${Number(id)}/midia`, {});
  },

  getAllPostByPeriodo(_dataInicio, _dataFim) {
    return axios.post(`/v1/post/periodo`, {
      dataInicio: _dataInicio,
      dataFim: _dataFim
    });
  },
  search(_search) {
    return axios.post(`/v1/post/search`, { search: _search });
  }
};
