import axios from "axios";

export default {
  salvar(valor) {
    return axios.post("/v1/cep", valor);
  },
  consultaCep(valor) {
    console.log("inter0")
    // Nova variável "cep" somente com dígitos.
    var cep = valor.replace(/\D/g, "");
    console.log("inter2")
    // Verifica se campo cep possui valor informado.
    if (cep !== "") {
      console.log("inter3")
      // Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;
      console.log("inter4")
      // Valida o formato do CEP.
      if (validacep.test(cep)) {
        console.log("inter5")
        return axios.get(`/v1/cep/${cep}`);
      }
    }
    console.log("inter6")
    return Promise.reject(new Error("CEP inválido"));
  }
};
