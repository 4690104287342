export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue")
  },
  {
    path: "/esqueci-minha-senha",
    name: "esqueci-minha-senha",
    component: () => import("@/views/esqueciSenha.vue")
  },
  {
    path: "/esqueci-minha-senha/:email/:codigo",
    name: "esqueci-minha-senha",
    component: () => import("@/views/esqueciSenha.vue")
  }
];
