import axios from "axios";

export default {
  getAllRoles() {
    return axios.get(`/v1/role/`);
  },
  getRestrictedRoles() {
    return axios.get(`/v1/role/restricted`);
  }
};
