export default {
  getProdutos(state) {
    return state.produtos;
  },
  getFiltros(state) {
    return state.filtros;
  },
  getEditProduto(state) {
    return state.editProduto;
  },
  getProdutosRelatorio(state){
    return state.produtosRelatorio
  }
};
