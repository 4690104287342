<template>
  <v-dialog v-model="show" width="400">
    <v-card>
      <v-card-title class="primary">
        <v-avatar slot="icon" color="deep-purple accent-4" size="40">
          <v-img :src="iconPath"></v-img>
        </v-avatar>
        <span class="headline ml-2 white--text">Instalar o App</span>
      </v-card-title>
      <v-card-text class="title">
        Adicionar um atalho na sua área de trabalho?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="notInstall">Não</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="install">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    install: {
      type: Function
    },
    notInstall: {
      type: Function
    }
  },
  beforeMount() {
    this.iconPath = require(`@/assets/${this.appProduto()}/icon-web.png`);
  }
};
</script>
