import axios from "axios";

export default {
  pagination() {
    return axios.get(`/v1/check/pagination`);
  },
  getTipoProdutos() {
    return axios.get(`/v1/check/combo-pesquisa`);
  },
  pesquisar(payload) {
    return axios.post(`/v1/check/pesquisa`, payload);
  },
  confirmaLeitura(codigo) {
    return axios.post(`/v1/check/confirma-leitura/${codigo}`);
  },
  leituraQrCode(_codigo) {
    return axios.post(`/v1/check/leitura-qrcode/${_codigo}`);
  },
  resumo(_id){
    return axios.post(`/v1/check/resumo/${_id}`)
  },
  resumoCategoria(_id){
    return axios.post(`/v1/check/resumo-categoria/${_id}`)
  },
  getRelatorio(){
    return axios.post(`/v1/check/resumo-desconto`, {
      descending: true,
      filtros: {
      },
      pageNo: 0,
      pageSize: 0,  
      sortBy: "id"
    })
  },
};
