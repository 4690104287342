import axios from "axios";

export default {
  logar(_login, _senha) {
    return axios.post("/v1/auth/login", { username: _login, password: _senha });
  },
  esqueciMinhaSenha(_login) {
    return axios.post(`/v1/auth/esqueci-minha-senha`, { username: _login });
  },
  alterarSenha(_email, _codigo, _novaSenha) {
    return axios.post("/v1/auth/alterar-senha", {
      email: _email,
      codigo: _codigo,
      novaSenha: _novaSenha
    });
  },
  getDadosUsuario(_login) {
    return axios.post(`/v1/auth/dadosusuario`, { username: _login });
  },

  salvar(user) {
    if (user.id && user.id != 0) {
      return axios.post(`/v1/usuario/${user.id}`, user);
    }
    return axios.post(`/v1/usuario`, user);
  },

  updateSenha(user) {
    if (user.id && user.id != 0) {
      return axios.post(`/v1/usuario/update-senha/${user.id}`, user);
    }
  },

  delete(id) {
    return axios.delete(`/v1/usuario/${id}`);
  },

  uploadFoto(id, formData) {
    if (id && id != 0) {
      return axios.post(`/v1/usuario/${Number(id)}/upload-foto`, formData, {});
    }
    return axios.post(`/v1/usuario/upload-foto-sem-usuario`, formData, {});
  },
  pagination(pageSize = 10, pageNo = 0, sortBy = "id", filtros) {
    return axios.post("/v1/usuario/pagination", {
      pageSize,
      pageNo,
      sortBy,
      filtros
    });
  }
};
