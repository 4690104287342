import Vue from "vue";
import VueRouter from "vue-router";
import PublicRouters from "./public";
import UserRouters from "./user";
import PostRouters from "./post";
import ClientRouters from "./client";
import CategoriaRouters from "./categoria";
import DashboardRouters from "./dashboard";
import MensagemRouters from "./mensagem";
import UsuarioExternoRouters from "./usuario_externo";
import ProdutosRouters from "./produto";
import EventoRouters from "./evento"
import LocaisRouters from "./locais"
import ConfigRouters from "./config";
import CheckinRouters from "./checkin";
import MidiaRouters from "./midias";
import PrivacidadeRouters from "./privacidade";
import ComissarioRouters from "./comissarios";
import RelatoriosRouters from "./relatorios"
import store from "@/store/index.js";
import { RoleDefaultPath } from "../constants";
import { isMobile } from "../constants";

const userDefaultRoute = () => {
  if(isMobile){
    return router.push(`/check-in`)
  }
  if (store.state.user.user.roleId && RoleDefaultPath[store.state.user.user.roleId]) {
    router.push(RoleDefaultPath[store.state.user.user.roleId])
    return;
  }
  router.push("/");
}

const jaLogado = (to, from, next) => {
  if (store.state.user.authStatus) {
    userDefaultRoute();
  } else {
    next();
  }
};

const requireAuth = (to, from, next) => {
  if (store.state.user.authStatus) {
    next();
  } else {
    router.push("/login");
  }
};

function validateRouter(path, name, component) {
  return {
    name,
    path,
    component,
    beforeEnter: ["login", "esqueci-minha-senha"].includes(name)
      ? jaLogado
      : requireAuth
  };
}

Vue.use(VueRouter);

const rotas = [
  {
    path: "/",
    component: () => import("@/views/dashboard/dashboard.vue")
  },
  ...UserRouters,
  ...PostRouters,
  ...PublicRouters,
  ...ClientRouters,
  ...CategoriaRouters,
  ...DashboardRouters,
  ...MensagemRouters,
  ...UsuarioExternoRouters,
  ...ProdutosRouters,
  ...ConfigRouters,
  ...EventoRouters,
  ...LocaisRouters,
  ...CheckinRouters,
  ...MidiaRouters,
  ...ComissarioRouters,
  ...PrivacidadeRouters,
  ...RelatoriosRouters,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: rotas
    .map(path => validateRouter(path.path, path.name, path.component))
    .concat([{ path: "*", redirect: "/" }])
});

export default router;
