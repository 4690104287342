import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "../locale/pt-br";

import leftArrow from "../components/assets/icons/leftArrow";

Vue.use(Vuetify);

import { colorsMilgrau as colors } from "../styles/colors";

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: "pt"
  },
  theme: {
    themes: {
      light: {
        primary: colors.primary,
        secondary: colors.secondary,
        tertiary: colors.tertiary,
        accent: colors.accent,
        error: colors.error,
        ice: colors.ice,
        white: colors.white,
        black: colors.black,
        btnCancelar: colors.btnCancelar,
        loginForm: colors.loginForm,
        backLogin: colors.backLogin,
        detail: "#757575"
      },
      dark: { disable: true }
    },
    options: {
      customProperties: true,
      variations: false
    }
  },
  icons: {
    values: {
      leftArrow: {
        component: leftArrow
      }
    }
  }
});

export default vuetify;
