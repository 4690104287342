import axios from "axios";

export default {
  getAll() {
    return axios.get(`/v1/instalacao`);
  },

  getAtual() {
    return axios.get(`/v1/instalacao/atual`);
  }
};
