import axios from "axios";

export default {
  salvar(tag) {
    if (tag.id && tag.id != 0) {
      return axios.post(`/v1/assunto/${tag.id}`, tag);
    }
    return axios.post(`/v1/assunto`, tag);
  },

  delete(id) {
    return axios.delete(`/v1/assunto/${id}`);
  },

  getAllTags() {
    return axios.get("/v1/assunto");
  },

  getTagById(id) {
    return axios.get(`/v1/assunto/${id}`);
  }
};
