export default {
  setUserAuth(state, payload) {
    state.auth = payload.auth;
  },
  setAuthStatus(state, payload) {
    let userAccess = state.user.token.accessToken;
    let userType = state.user.token.tokenType;
    if (userAccess && userType) {
      state.authStatus = payload;
    } else {
      state.authStatus = false;
    }
  },
  setTokenAuth(state, payload) {
    state.user.token = payload;
  },
  setUserData(state, payload) {
    state.user.nome = payload.nome;
    state.user.id = payload.id;
    state.user.email = payload.email;
    state.user.roleLabel = payload.roleLabel;
    state.user.roleId = payload.roleId;
    state.user.clientes = payload.clientes;
    // state.user.senha = payload.senha;
    state.user.fotoUrl = payload.fotoUrl;
  }
};
