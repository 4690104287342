import Vue from "vue";
import Vuex from "vuex";
import StoreUser from "./user/index";
import StoreTipos from "./tipos/index";
import StoreMarketplace from "./marketplace";
import StoreInstalacao from "./instalacao";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: StoreUser,
    tipos: StoreTipos,
    marketplace: StoreMarketplace,
    instalacao: StoreInstalacao
  },
  plugins: [createPersistedState()]
});
