import axios from "axios";

export default {
    cadastroLocal(_payload){
        return axios.post('/v1/local', {
            descricao: _payload.descricao,
            imagemMapa: _payload.imagemMapa,
            imagemWidth: _payload.imagemWidth,
            imagemHeight: _payload.imagemHeight,
            clienteId: _payload.clienteId,
            tipoLocalId: _payload.tipoLocalId,
            habilitaEditor: _payload.habilitaEditor,
            cep: _payload.cep,
            estado: _payload.estado,
            bairro: _payload.bairro,
            logradouro: _payload.logradouro,
            numero: _payload.numero,
            complemento: _payload.complemento
        })
    },

    getLocalDetalhe(_id){
        return axios.get(`/v1/local/${_id}`)
    },

    getLocalItem(_id){
        return axios.get(`/v1/local/item/${_id}`)
    },

    getTipos(){
        return axios.get('/v1/local/tipos')
    },


    editLocal(_payload){
        return axios.post('/v1/local', {
            descricao: _payload.descricao,
            imagemMapa: _payload.imagemMapa,
            imagemWidth: _payload.imagemWidth,
            imagemHeight: _payload.imagemHeight,
            clienteId: _payload.clienteId,
            tipoLocalId: _payload.tipoLocalId,
            id: _payload.id,
            habilitaEditor: _payload.habilitaEditor,
            cep: _payload.cep,
            estado: _payload.estado,
            bairro: _payload.bairro,
            logradouro: _payload.logradouro,
            numero: _payload.numero,
            complemento: _payload.complemento
        })
    },

    saveImagem(_payload){
        return axios.post('/v1/local/imagem', {
            imagemWidth: _payload.imagemWidth,
            imagemHeight: _payload.imagemHeight,
            id: _payload.id,
        })
    },

    deleteLocal(_id){
        return axios.delete(`/v1/local/${_id}`)
    },

    pagination(pageSize = 10, pageNo = 0, sortBy = "id", filtros) {
        return axios.get("/v1/local", {
          pageSize,
          pageNo,
          sortBy,
          filtros
        });
    },

    localDetalhe(_id){
        return axios.get(`/v1/local/${_id}`)
    },

    cadastroItem(_payload){
        return axios.post('/v1/local/item', {
            localId: _payload.localId,
            descricao: _payload.descricao,
            coordX: _payload.coordx,
            coordY: _payload.coordy,
            tipoAssentoId: _payload.tipoAssentoId,
            situacaoItemId: _payload.situacaoItemId,
            localCategoriaId : _payload.localCategoriaId,
            sigla: _payload.sigla,
            id: _payload.id

        })
    },


    saveAllCadeiras(_payload){
        return axios.post('/v1/local/item/all', _payload);
    },

    editeItem(_payload){
        return axios.post('/v1/local/item', {
            localId: _payload.localId,
            descricao: _payload.descricao,
            coordx: _payload.coordx,
            coordy: _payload.coordy,
            tipoAssentoId: _payload.tipoAssentoId,
            id: _payload.id

        })
    },

    
    getTiposAssentos(){
        return axios.get('/v1/local/item/tipos')
    },
}