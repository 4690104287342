import axios from 'axios'

export default {
    getMovimentacaoAfiliado(){
        return axios.get('/v1/financeiro/movimentacao/afiliado');
    },

    getMovimentacaoCliente(){
        return axios.get('/v1/financeiro/movimentacao/cliente');
    },

    getMovimentacaoPlataforma(){
        return axios.get('/v1/financeiro/movimentacao/plataforma');
    },

    getMovimentacaoDateAfiliado(_mes, _ano){
        return axios.get(`/v1/financeiro/movimentacao/afiliado/${_mes}/${_ano}`);
    },

    getMovimentacaoDateCliente(_mes, _ano){
        return axios.get(`/v1/financeiro/movimentacao/cliente/${_mes}/${_ano}`);
    },

    getMovimentacaoDatePlataforma(_mes, _ano){
        return axios.get(`/v1/financeiro/movimentacao/plataforma/${_mes}/${_ano}`);
    },

    getCalendario(){
        return axios.get('/v1/financeiro/calendario')
    },
    
    getExtrato(mes, ano){
        return axios.get(`/v1/financeiro/extrato/${mes}/${ano}`)
    },

    downloadRelatorio(payload){
        return axios.post("/v1/financeiro/download/xlsx", payload, {
            responseType: 'blob',
          })
    },

    uploadRelatorio(payload){
        return axios.post("/v1/financeiro/upload/xlsx", payload)
    },

    enviarEmail(payload){
        return axios.post("/v1/financeiro/transferencia/", payload)
    }
}
