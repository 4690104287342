import axios from "axios";

export default {
  salvar(categoria) {
    if (categoria.id && categoria.id != 0) {
      return axios.post(`/v1/assunto/${categoria.id}`, categoria);
    }
    return axios.post(`/v1/assunto`, categoria);
  },

  delete(id) {
    return axios.delete(`/v1/assunto/${id}`);
  },

  getAll() {
    return axios.get("/v1/assunto");
  }
};
