<template>
  <div class="text-center">
    <v-dialog value="true" persistent width="900px">
      <template v-if="loading">
        <v-row no-gutters style="height: 90vh" class="text-center">
          <v-col align-self="center">
            <v-progress-circular indeterminate color="primary" :size="70" />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-card>
          <v-toolbar style="margin-bottom: 5px" elevation="1" flat>
            <v-toolbar-title>
              <span class="headline">{{ formTitle }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon large @click="closeDialog">mdi-close</v-icon>
          </v-toolbar>
          <v-row no-gutters class="pa-2">
            <v-col md="4" sm="6" xm="12" cols="12" align="center">
              <v-container
                class="grey lighten-5"
                style="border: 3px dashed #cccccc !important"
              >
                <v-row no-gutters style="height: 250px">
                  <v-col align-self="center">
                    <template v-if="loadingMidia">
                      <v-progress-circular indeterminate color="primary" />
                    </template>
                    <template v-else>
                      <template v-if="post.midiaUrl">
                        <videoPost
                          :payload="post"
                          v-if="post.tpMidia == 'VIDEO'"
                          :height="height"
                          :width="width"
                        />
                        <v-img
                          v-else
                          contain
                          :max-height="height"
                          :src="post.midiaUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <template v-else>
                        <v-form enctype="multipart/form-data">
                          <label
                            for="midia"
                            style="cursor: pointer"
                            class="caption"
                            title="midia"
                          >
                            <v-icon x-large color="primary"
                              >mdi-cloud-upload</v-icon
                            ><br />
                            <span class="font-weight-bold"
                              >Carregar arquivo*</span
                            >
                            <input
                              id="midia"
                              :disabled="
                                post.pacoteId && post.tipoPublicacao == 'E'
                              "
                              style="display: none"
                              type="file"
                              @change="uploadMidia($event)"
                            />
                          </label>
                        </v-form>
                      </template>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
              <v-row v-if="post.midiaUrl">
                <v-col align="end">
                  <v-icon
                    v-if="!post.isPacote"
                    color="primary"
                    @click="deleteMidia"
                    :disabled="readOnly"
                    >mdi-delete-outline</v-icon
                  >
                </v-col>
              </v-row>
              <v-row
                class="ml-2 mt-4"
              >
                <v-col cols="12">
                  <span class="text-body-1 text-center">
                    ou escolha um pacote</span
                  >
                  <v-divider />
                </v-col>

                <div v-if="pacotes" class="d-flex flex-wrap">
                  <v-autocomplete
                    v-model="post.pacoteId"
                    v-if="post.situacaoPublicacao !== 'PUBLICADO'"
                    :items="pacotes"
                    :disabled="post.midiaUrl || readOnly"
                    label="Pacotes"
                    data-vv-name="Pacotes"
                    item-text="descricao"
                    item-value="id"
                    prepend-icon="mdi-map"
                    required
                  />
                </div>
                <div v-if="pacotes == []">
                  <span> Não há nenhum pacote liberado</span>
                </div>
              </v-row>
              <v-row class="ml-4 mt-4">
                <div v-if="post.situacaoPublicacao === 'PUBLICADO'">
                  <v-icon> mdi-map </v-icon>
                  <span> {{ post.pacoteDescricao }} </span>
                </div>
              </v-row>

              <v-row>
                <v-col cols="12" align="start" class="mt-4">
                  <span class="subtitle-2">Situação</span>
                  <p class="text--disabled">
                    {{ situacao(post.situacaoPublicacao) }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="8" sm="6" xm="12" cols="12">
              <v-card elevation="0">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="salvar(null)"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="col-no-padding">
                        <span class="subtitle-2">Agendar postagem</span>
                      </v-col>
                      <v-col cols="12" sm="6" class="pt-0">
                        <v-datetime-picker
                          v-model="post.dhInicioPublicacao"
                          label="Data Início Publicação*"
                          dateFormat="dd/MM/yyyy"
                          datetime="date"
                          clearText="limpar"
                          :disabled="readOnly"
                        >
                          <template v-slot:dateIcon>
                            <v-icon>mdi-calendar-outline</v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon>mdi-clock-outline</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-col>
                      <v-col cols="12" md="6" sm="12" class="pt-0">
                        <v-datetime-picker
                          v-model="post.dhFimPublicacao"
                          label="Data Fim Publicação*"
                          dateFormat="dd/MM/yyyy"
                          datetime="date"
                          clearText="limpar"
                          :disabled="true"
                        >
                          <template v-slot:dateIcon>
                            <v-icon>mdi-calendar-outline</v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon>mdi-clock-outline</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="col-no-padding pb-0">
                        <span class="subtitle-2">Legenda do post</span>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-textarea
                          ref="legenda"
                          v-model="post.legenda"
                          rows="1"
                          auto-grow
                          name="legenda"
                          :rules="[rules.required]"
                          :disabled="readOnly"
                          class="fontLegenda col-no-padding"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pb-0 pt-0">
                        <span class="subtitle-2">Hashtags</span>
                      </v-col>
                      <v-col cols="12" class="col-no-padding">
                        <v-text-field
                          v-model="post.hashtags"
                          name="Hashtags"
                          placeholder="#MilGrau #iLoveTainha #LagoaDaConceicao "
                          hint="Digite as hash tags separadas por espaço"
                          :disabled="readOnly"
                          class="col-no-padding"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <span class="subtitle-2">Links</span>
                      </v-col>
                      <v-col cols="12" class="col-no-padding">
                        <v-text-field
                          v-model="post.link"
                          name="Links"
                          placeholder="www.milgrau.com www.google.com.br www.facebook.com.br"
                          :disabled="readOnly"
                          class="col-no-padding"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      justify="center"
                    >
                      <v-col cols="2">
                        <span class="subtitle-2">Tipo</span>
                      </v-col>
                      <v-col cols="10" class="pa-0 ma-0">
                        <v-radio-group
                          v-model="post.tipoPublicacao"
                          row
                          :disabled="readOnly"
                        >
                          <v-radio label="Aberta" value="A"></v-radio>
                          <v-radio v-if="!desabilitaConteudoExclusivo" label="Exclusiva" value="E"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        isTipoPublicacaoAberta &&
                        instalacao.habilitaRegiaoInteresse
                      "
                    >
                      <!-- REGIAO INTERESSE -->
                      <v-col cols="12" md="6" sm="12">
                        <span class="subtitle-2">Região de interesse</span>
                        <v-autocomplete
                          v-model="post.regiaoInteresseId"
                          :items="regioesInteresse"
                          item-text="descricao"
                          item-value="id"
                          clearable
                          :disabled="readOnly"
                          class="col-no-padding"
                        ></v-autocomplete>
                      </v-col>

                      <!-- MUNICIPIOS -->
                      <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        v-if="
                          post.regiaoInteresseId == 2 ||
                          post.regiaoInteresseId == 3
                        "
                      >
                        <span class="subtitle-2">Município</span>
                        <v-autocomplete
                          v-model="post.cidadeId"
                          :items="cidades"
                          item-text="descricao"
                          item-value="id"
                          clearable
                          :disabled="readOnly"
                          class="col-no-padding"
                          @change="getBairros"
                        ></v-autocomplete>
                      </v-col>

                      <!-- BAIRROS -->
                      <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        class="pt-4"
                        v-if="post.regiaoInteresseId == 3"
                      >
                        <span class="subtitle-2">Bairro(s)</span>
                        <v-autocomplete
                          v-model="post.selectedBairros"
                          :items="bairros"
                          item-text="descricao"
                          item-value="id"
                          multiple
                          chips
                          deletable-chips
                          small-chips
                          :disabled="readOnly || !hasCidadeSelected"
                          class="col-no-padding"
                          :loading="loadingBairros"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row v-if="!isTipoPublicacaoAberta">
                      <!-- PLANO -->
                      <v-col cols="12" md="6" sm="12">
                        <span class="subtitle-2">Tipo de Pagamento</span>
                        <v-autocomplete
                          v-model="post.planoId"
                          :items="getPlanos()"
                          item-text="descricao"
                          item-value="id"
                          clearable
                          :disabled="readOnly"
                          class="col-no-padding"
                        ></v-autocomplete>
                      </v-col>
                      <!-- VALOR -->
                      <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        class="pt-3"
                        v-if="isPostagemExclusiva"
                      >
                        <span class="subtitle-2 pb-0">Valor</span>
                        <vuetify-money
                          v-model="post.valor"
                          :placeholder="
                            'Valor mínimo: ' + valorMinimoConteudoExclusivo
                          "
                          :disabled="readOnly"
                          :properties="{
                            class: 'pt-0',
                          }"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Categorias -->
                      <v-col cols="12" md="6" sm="12" class="pt-4">
                        <span class="subtitle-2">Categorias</span>
                        <v-autocomplete
                          v-model="post.selectedAssuntos"
                          :items="assuntos"
                          item-text="descricao"
                          item-value="id"
                          item-color="color"
                          chips
                          deletable-chips
                          small-chips
                          :rules="[rules.required]"
                          :search-input.sync="search"
                          :disabled="readOnly"
                          multiple
                          class="col-no-padding"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="remove(data.item)"
                              :color="data.item.color"
                              label
                            >
                              {{ data.item.descricao }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-chip
                                label
                                small
                                :color="data.item.color"
                                class="mr-2"
                              >
                              </v-chip>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.descricao"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <!-- CLIENTES -->
                      <v-col cols="12" md="6" sm="12">
                        <span class="subtitle-2">Cliente</span>
                        <v-autocomplete
                          v-model="post.clienteId"
                          :items="clientes"
                          item-text="descricao"
                          item-value="id"
                          clearable
                          :rules="[rules.required]"
                          :disabled="readOnly"
                          class="col-no-padding"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <span class="subtitle-2">Notificação</span>
                      </v-col>
                      <v-col cols="9" class="pa-0 ma-0">
                        <v-switch
                          v-model="post.isNotificado"
                          color="primary"
                          class="pa-0 ma-0 mt-2"
                          :disabled="readOnly"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row v-if="post.isNotificado">
                      <v-col cols="12" class="col-no-padding pb-0">
                        <span class="subtitle-2">Texto da notificação</span>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-textarea
                          v-model="post.descricaoNotificacao"
                          rows="1"
                          auto-grow
                          :rules="[rules.required]"
                          :disabled="readOnly"
                          class="fontLegenda col-no-padding"
                          :counter="255"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btnCancelar" text @click="closeDialog">
                      <span class="text-capitalize">Cancelar</span>
                    </v-btn>
                    <v-menu top :offset-y="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!valid"
                          color="primary"
                          :loading="loadingSalvar"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="text-capitalize">Outras Opções</span>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <span v-for="(item, i) in otherOptionsItems" :key="i">
                          <v-list-item
                            v-if="item.show"
                            link
                            @click="item.action"
                            color="primary"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </span>
                      </v-list>
                    </v-menu>
                    <v-btn
                      :disabled="!valid || readOnly"
                      color="primary"
                      type="submit"
                      :loading="loadingSalvar"
                    >
                      <span class="text-capitalize">Salvar Edição</span>
                    </v-btn>
                    <v-btn
                      :disabled="!valid || readOnly"
                      color="primary"
                      :loading="loadingSalvar"
                      @click="programar"
                    >
                      <span class="text-capitalize">Programar</span>
                    </v-btn>
                    <v-btn
                      :disabled="!valid || readOnly"
                      color="primary"
                      :loading="loadingSalvar"
                      @click="publicar"
                    >
                      <span class="text-capitalize">Publicar</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import {
  CidadeService,
  PostService,
  TagService,
  TiposService,
  PlanoService,
  ConfiguracaoGeralService,
  PacoteMidiasService,
  InstalacaoService,
} from "../../services";
import { Role } from "../../constants";

import videoPost from "./videoComp";
import moment from "moment";

export default {
  components: { videoPost },
  props: {
    callback: {
      type: Function,
    },
    closeDialog: {
      type: Function,
    },
    post: {
      type: Object,
      default: PostService.defaultPost(),
    },
    disabledAprovar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    height: 250,
    width: 250,
    options: {
      muted: true,
      autoplay: false,
    },
    playerReady: false,
    erroLoadingVimeo: false,
    valid: false,
    search: null,
    editedIndex: -1,
    menu: false,
    readOnly: false,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    loadingMidia: false,
    loadingSalvar: false,
    loading: false,
    formData: null,
    file: {},
    newTag: {
      nome: "",
      color: "",
    },
    clientes: [],
    assuntos: [], // TAGs
    situacoesPublicacao: [],
    cidades: [],
    bairros: [],
    userData: {},
    configGeral: [],
    instalacao: [],
    loadingBairros: false,
    regioesInteresse: [],
    valorMinimoConteudoExclusivo: null,
    valorMaximoConteudoExclusivo: 999999.99,
    maxSizeDescricaoNotificacao: 255,
    pacotes: [],
  }),
  mounted() {
    this.getPacotes();
    this.post.dhPublicacao = moment(
      this.post.dhPublicacao,
      moment.defaultFormat
    ).toDate();
  },
  computed: {
    formTitle() {
      return this.post.id ? "Alterar post" : "Novo post";
    },
    hasCidadeSelected() {
      return this.post.cidadeId !== null;
    },
    isTipoPublicacaoAberta() {
      return this.post.tipoPublicacao === "A";
    },
    otherOptionsItems() {
      return [
        {
          title: "Rejeitar",
          action: this.rejeitar,
          show: !this.postStatusCancelado,
        },
        {
          title: "Excluir",
          action: this.excluir,
          show: !this.readOnly || this.postStatusCancelado,
        },
        {
          title: "Reabrir",
          action: this.reabrir,
          show: this.postStatusCancelado && !this.postStatusPublicado,
        },
      ];
    },
    showMunicipio() {
      return (
        this.instalacao &&
        Object.keys(this.instalacao).length > 0 &&
        (this.instalacao.habilitaMunicipio == null ||
          this.instalacao.habilitaMunicipio == true)
      );
    },
    showBairros() {
      return (
        this.showMunicipio &&
        this.instalacao &&
        Object.keys(this.instalacao).length > 0 &&
        (this.instalacao.habilitaBairro == null ||
          this.instalacao.habilitaBairro == true)
      );
    },
    showConteudoExclusivo() {
      return (
        this.instalacao &&
        Object.keys(this.instalacao).length > 0 &&
        (this.instalacao.habilitaPagamentoExclusivo == null ||
          this.instalacao.habilitaPagamentoExclusivo == true)
      );
    },
    desabilitaConteudoExclusivo() {
     return (
        (this.instalacao.habilitaPagamentoExclusivo == null || this.instalacao.habilitaPagamentoExclusivo == false) &&
        (this.instalacao.habilitaPagamentoPlano == null || this.instalacao.habilitaPagamentoPlano == false)
      );
    },
    isPostagemExclusiva() {
      return this.post.planoId === 2;
    },
    dhInicioPublicacao() {
      return this.post.dhInicioPublicacao;
    },
    postStatusCancelado() {
      return this.post.situacaoPublicacao === "CANCELADO";
    },
    postStatusPublicado() {
      return this.post.situacaoPublicacao === "APROVADO";
    },
    mesesDataFim() {
      return this.configGeral != null &&
        this.configGeral.length > 0 &&
        this.configGeral.numeroMesesDataFim != null
        ? this.configGeral.numeroMesesDataFim
        : 6;
    },
    isAdminOrRoot() {
      return [Role.ADMIN, Role.ROOT].includes(this.userData.roleId);
    },
    habilitaPacote() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaPacotes;
    },
  },
  watch: {
    dhInicioPublicacao(val) {
      this.post.dhFimPublicacao = new Date(
        moment(val).add(this.mesesDataFim, "M").valueOf()
      );
    },
    isPostagemExclusiva() {
      if (!this.isPostagemExclusiva) {
        this.post.valor = null;
      }
    },
    instalacao(instalacao) {
      if (!this.post.id) {
        this.post.isVod = instalacao.habilitaVod;
      }
    },
  },
  beforeMount() {
    this.loading = true;
    this.userData = this.$store.getters["user/getUser"];
    this.clientes = this.$store.getters["tipos/getClientes"];
    this.cidades = this.$store.getters["tipos/getCidades"];
    this.situacoesPublicacao =
      this.$store.getters["tipos/getSituacoesPublicacao"];

    this.readOnly = [
      "APROVADO",
      "CONCLUIDO",
      "PUBLICADO",
      "CANCELADO",
    ].includes(this.post.situacaoPublicacao);
    if (this.post.situacaoPublicacao === "APROVADO" && this.isAdminOrRoot) {
      this.readOnly = false;
    }

    var promisseClientes = TiposService.getAllClientes().then((response) => {
      this.clientes = response.data;
      this.$store.commit("tipos/setClientes", this.clientes);
      let defaultCliente = this.clientes.filter(
        (cliente) => cliente.flgDefault
      );
      if (defaultCliente && defaultCliente.length > 0) {
        this.post.clienteId = defaultCliente[0].id;
      }
    });

    var promisseAssuntos = TagService.getAllTags().then((response) => {
      this.assuntos = response.data;
    });

    var promisseSituacoes = TiposService.getAllSituacoesPublicacao().then(
      (response) => {
        this.situacoesPublicacao = response.data;
        this.$store.commit(
          "tipos/setSituacoesPublicacao",
          this.situacoesPublicacao
        );
      }
    );

    var promisseCidades = CidadeService.getAllCidadesSC().then((response) => {
      this.cidades = response.data;
      this.$store.commit("tipos/setCidades", this.cidades);
    });

    // var promissePlanos = PlanoService.getAll().then(response => {
    //   this.planos = response.data;
    // });

    var promisseConfigGeral =
      ConfiguracaoGeralService.getConfiguracaoGeral().then((response) => {
        this.configGeral = response.data;
      });

    var promisseInstalacao = InstalacaoService.getAtual().then((response) => {
      this.instalacao = response.data;
    });

    var promisseRegioesInteresse =
      TiposService.getAllTipoRegiaoInteresse().then((response) => {
        this.regioesInteresse = response.data;
      });

    var promisseConteudoExclusivo =
      PlanoService.getValorConteudoExclusivo().then((response) => {
        this.valorMinimoConteudoExclusivo = response.data.valorMinimo;
        if (!this.post.valor) {
          this.post.valor = response.data.valor;
        }
      });

    if (this.post.cidadeId) {
      this.getBairros();
    }

    Promise.all([
      promisseClientes,
      promisseAssuntos,
      promisseSituacoes,
      promisseCidades,
      // promissePlanos,
      promisseConfigGeral,
      promisseInstalacao,
      promisseRegioesInteresse,
      promisseConteudoExclusivo,
    ]).finally(() => {
      this.loading = false;
      // console.log(this.post)
    });
  },
  methods: {
    getPlanos() {
      let planos = [
        {
          id: 1,
          descricao: "Plano de Assinatura",
        },
      ];

      if (this.instalacao.habilitaPagamentoExclusivo && this.instalacao.habilitaPagamentoPlano) {
        planos.push({
          id: 2,
          descricao: "Publicação Exclusiva",
        });
      }

      return planos;
    },
    salvar(novaSituacao) {
      if (novaSituacao == "APROVADO") {
        if (this.$refs.form.validate()) {
          if (!this.validaForm(novaSituacao)) {
            return;
          }

          this.salvar2(novaSituacao);
        } else {
          this.$root.showErro("Todos os campos são obrigatórios");
        }
      } else {
        this.salvar2(novaSituacao);
      }
    },
    salvar2(novaSituacao) {
      if (!this.post.dhInicioPublicacao) {
        this.$root.showErro(
          "O campo <span class='font-weight-black'>data/hora início da publicação</span> é obrigatório!"
        );
        return false;
      }

      this.loadingSalvar = true;

      let oldSituacao = this.post.situacaoPublicacao;

      if (novaSituacao !== null) {
        this.post.situacaoPublicacao = novaSituacao;
      } else if (oldSituacao === "PENDENTE") {
        this.post.situacaoPublicacao = "AGUARDANDO";
      }

      PostService.salvar(this.post)
        .then((response) => {
          this.post.id = response.data.id;
          this.$root.showSucesso("Operação realizada com sucesso");
          this.callback();
          this.closeDialog();
        })
        .catch((erro) => {
          this.post.situacaoPublicacao = oldSituacao;
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    validaForm(novaSituacao) {
      if (
        this.post.isNotificado &&
        this.post.descricaoNotificacao.length > this.maxSizeDescricaoNotificacao
      ) {
        this.$root.showErro(
          `O texto da notificação deve ter no máximo ${this.maxSizeDescricaoNotificacao} caracteres`
        );
        return false;
      }

      if (
        this.post.planoId == 2 &&
        this.post.valor < this.valorMinimoConteudoExclusivo
      ) {
        this.$root.showErro(
          "O <span class='font-weight-black'>valor da publicação exclusiva</span> deve ser maior ou igual a <span class='font-weight-black'>" +
            this.valorMinimoConteudoExclusivo +
            "</span>!"
        );
        return false;
      }

      if (
        this.post.planoId == 2 &&
        this.post.valor > this.valorMaximoConteudoExclusivo
      ) {
        this.$root.showErro(
          "O <span class='font-weight-black'>valor da publicação exclusiva</span> deve ser menor ou igual a <span class='font-weight-black'>" +
            this.asMoney(this.valorMaximoConteudoExclusivo) +
            "</span>!"
        );
        return false;
      }

      if (!this.post.dhInicioPublicacao) {
        this.$root.showErro(
          "O campo <span class='font-weight-black'>data/hora início da publicação</span> é obrigatório!"
        );
        return false;
      }

      if (novaSituacao != null && !this.post.dhFimPublicacao) {
        this.$root.showErro(
          "O campo <span class='font-weight-black'>data/hora fim da publicação</span> é obrigatório!"
        );
        return false;
      }

      if (
        novaSituacao != null &&
        novaSituacao != "APROVADO" &&
        novaSituacao != "CANCELADO" &&
        this.post.dhInicioPublicacao < new Date()
      ) {
        this.$root.showErro(
          "A <span class='font-weight-black'>data/hora início da publicação</span> não pode ser menor que a <span class='font-weight-black'>data/hora atual.</span>"
        );
        return false;
      }

      if (
        novaSituacao != null &&
        this.post.dhFimPublicacao < this.post.dhInicioPublicacao
      ) {
        this.$root.showErro(
          "A <span class='font-weight-black'>data/hora início da publicação</span> não pode ser menor que a <span class='font-weight-black'>data/hora fim da publicação.</span>"
        );
        return false;
      }

      if (!this.post.midiaUrl && !this.post.pacoteId) {
        this.$root.showErro(
          "Informe um arquivo de <span class='font-weight-black'>vídeo ou imagem!</span>"
        );
        return false;
      }

      if (
        !this.post.selectedAssuntos ||
        this.post.selectedAssuntos.length === 0
      ) {
        this.$root.showErro(
          "Informe pelo menos uma <span class='font-weight-black'>Categoria!</span>"
        );
        return false;
      }

      if (this.post.regiaoInteresseId) {
        if (
          this.post.regiaoInteresseId == 2 ||
          this.post.regiaoInteresseId == 3
        ) {
          if (this.post.cidadeId == null) {
            this.$root.showErro(
              "O campo <span class='font-weight-black'>Município</span> é obrigatório!"
            );
            return false;
          }
          if (
            this.post.regiaoInteresseId == 3 &&
            this.post.selectedBairros.length === 0
          ) {
            this.$root.showErro(
              "O campo <span class='font-weight-black'>Bairro(s)</span> é obrigatório!"
            );
            return false;
          }
        }
      }

      return true;
    },
    addNewTag() {
      if (this.search && this.newTag.color) {
        this.newTag.descricao = this.search;
        TagService.salvar(this.newTag)
          .then((response) => {
            this.$root.showSucesso("Tag add com sucesso");
            this.post.selectedAssuntos.push(response.data.id);
            this.assuntos.push(response.data);
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          });
      } else {
        this.$root.showAlerta("Informe um nome e uma cor para nova Tag");
      }
    },
    remove(item) {
      const index = this.post.selectedAssuntos.indexOf(item.id);
      if (index >= 0) this.post.selectedAssuntos.splice(index, 1);
    },
    uploadMidia(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingMidia = true;
        PostService.uploadMidia(this.post.id, this.formData)
          .then((response) => {
            this.post.midiaUrl = response.data.midiaUrl;
            this.post.tpMidia = response.data.tpMidia;
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },
    deleteMidia() {
      if (this.post.id) {
        this.loadingMidia = true;
        PostService.deleteMidia(this.post.id)
          .then((response) => {
            this.$root.showSucesso(response.data);
            this.loadingMidia = false;
            this.post.midiaUrl = null;
            this.post.tpMidia = null;
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
            this.loadingMidia = true;
          });
      } else {
        this.post.midiaUrl = null;
        this.post.tpMidia = null;
      }
    },
    disabledSituacao() {
      if (!this.post.id) {
        return true;
      }

      if (!this.isAdminOrRoot) {
        return true;
      }
      return this.readOnly;
    },
    getBairros() {
      this.loadingBairros = true;
      CidadeService.getBairrosByCidade(this.post.cidadeId)
        .then((response) => {
          this.bairros = response.data;
        })
        .finally(() => {
          this.loadingBairros = false;
        });
    },
    publicar() {
      this.post.dhInicioPublicacao = new Date();
      this.post.dhFimPublicacao = new Date(
        moment(this.post.dhInicioPublicacao)
          .add(this.mesesDataFim, "M")
          .valueOf()
      );
      this.post.pacoteId
        ? (this.post.isPacote = true)
        : (this.post.isPacote = false);
      this.salvar("APROVADO");
    },
    programar() {
      this.post.dhFimPublicacao = new Date(
        moment(this.post.dhInicioPublicacao)
          .add(this.mesesDataFim, "M")
          .valueOf()
      );
      this.post.pacoteId
        ? (this.post.isPacote = true)
        : (this.post.isPacote = false);
      this.salvar("APROVADO");
    },
    rejeitar() {
      this.salvar("CANCELADO");
    },
    excluir() {
      this.salvar("EXCLUIDO");
    },
    reabrir() {
      this.post.dhInicioPublicacao = new Date();
      this.post.dhFimPublicacao = new Date(
        moment(this.post.dhInicioPublicacao)
          .add(this.mesesDataFim, "M")
          .valueOf()
      );
      this.salvar("AGUARDANDO");
    },
    situacao(situacao) {
      let situacoes = PostService.situacoes();
      return situacoes[situacao];
    },

    getPacotes() {
      PacoteMidiasService.getLiberados()
        .then((response) => {
          this.pacotes = response.data.map((v) => ({ ...v, isActive: false }));
        })
        .finally(() => {});
    },

    handlePacote(item) {
      item.isActive = !item.isActive;
      this.post.pacoteId = item.id;
      if (this.pacotes.filter((i) => i.isActive).length > 1) {
        let i,
          n = this.pacotes.length;
        for (i = 0; i < n; ++i) {
          this.pacotes[i].isActive = false;
        }
        item.isActive = true;
      }
    },

    definirPacote(id) {
      PacoteMidiasService.definirPacote(id)
        .then((response) => {
          console.log(response);
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.col-no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.fontLegenda {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.selecionado {
  background-color: "#160425";
  color: "white";
}
</style>
