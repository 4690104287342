import axios from "axios";

export default {
    cadastroEvento(_payload){
        return axios.post('/v1/evento', {
            legenda: _payload.legenda,
            midia: _payload.midia,
            tpMidia: _payload.tpMidia,
            clienteId: _payload.clienteId
        })
    },

    getEventoDetalhe(_id){
        return axios.get(`/v1/evento/${_id}`)
    },

    editEvento(_payload){
        return axios.post('/v1/evento', {
            legenda: _payload.legenda,
            midia: _payload.midia,
            tpMidia: _payload.tpMidia,
            clienteId: _payload.clienteId,
            id: _payload.id
        })
    },

    deleteEvento(_id){
        return axios.delete(`/v1/evento/${_id}`)
    },

    pagination(pageSize = 10, pageNo = 0, sortBy = "id", filtros) {
        return axios.get("/v1/evento", {
          pageSize,
          pageNo,
          sortBy,
          filtros
        });
    },

    eventoDetalhe(_id){
        return axios.get(`/v1/evento/${_id}`)
    },
}