import axios from "axios";

export default {
  getAllClientes() {
    return axios.get("/v1/tipos/clientes");
  },
  getAllAssuntos() {
    return axios.get("/v1/tipos/assuntos");
  },
  getAllRoles() {
    return axios.get("/v1/tipos/roles");
  },
  getAllSituacoesPublicacao() {
    return axios.get("/v1/tipos/situacoes-publicacao");
  },
  getAllTiposPublicacao() {
    return axios.get("/v1/tipos/tipos-publicacao");
  },
  getAllTipoMensagens() {
    return axios.get("/v1/tipos/tipo-mensagens");
  },
  getAllTipoRegiaoInteresse() {
    return axios.get("/v1/tipos/regioes-interesse");
  },
  getClassificacaoEventos() {
    return axios.get("/v1/tipos/classificacao-evento");
  },
  getTipoIngresso() {
    return axios.get("/v1/tipos/tipo-ingresso");
  },
  getLoteIngresso() {
    return axios.get("/v1/tipos/lote-ingresso");
  },
  getAreaIngresso() {
    return axios.get("/v1/tipos/area-ingresso");
  },
  getTiposPagamento(){
    return axios.get("/v1/tipos/tipo-pagamento")
  },
  getParceiros(){
    return axios.get("/v1/tipos/parceiros")
  }
};
