import axios from "axios"

let local = {
    getProdutos(){
        return axios.get("/v1/marketplace/produtos/consulta")
    }
}

export default {
    setProdutosRelatorio(_context) {
        let detalhe = local.getProdutos()
        detalhe.then(resp => {
            _context.commit("setProdutosRelatorio", resp.data);
          });
          return detalhe;
    },
}