export default [
    {
      path: "/pacote-midias",
      component: () => import("@/views/midias/index.vue")
    },

    {
      path: "/cadastro-midias",
      component: () => import("@/views/midias/cadastroPacote.vue")
    }
  ];
  