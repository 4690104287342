/* eslint-disable */
import axios from 'axios'

export default {

  getAllCidadesSC() {
    return axios.get('/v1/cidade/sc')
  },

  getBairrosByCidade (cidadeId) {
    return axios.get(`/v1/bairros/cidade/${cidadeId}`)
  }
}
