export default {
  menu: {
    toggle: false
  },
  authStatus: false,
  modal: {
    tipo: "info",
    toggle: false,
    mensagem: ""
  },
  user: {
    nome: "",
    id: "",
    email: "",
    roleLabel: "",
    roleId: "",
    senha: "",
    fotoUrl: "",
    clientes: null,
    token: {
      tokenType: undefined,
      accessToken: undefined
    }
  }
};
