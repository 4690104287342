import axios from "axios";

export default {
    salvarCortesia(payload) {
        return axios.post('/v1/cortesia', {
            categoriaId: payload.produtoDetalheId,
            usuarioExternoId: payload.usuarioExternoId,
            quantidade: payload.quantidade,
        })
    },
  
    delete(_id) {
      return axios.delete(`/v1/cortesia/${_id}`);
    },
  
    getAll(id) {
      return axios.get("/v1/cortesia", {
        id: id
      });
    },

    getOne(_id){
        return axios.get(`/v1/cortesia/produto/${_id}`)
    }
  };
  