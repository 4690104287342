export default {
  badge: "Distintivo",
  close: "Fechar",
  dataIterator: {
    noResultsText: "Nenhum dado encontrado",
    loadingText: "Carregando itens..."
  },
  dataTable: {
    itemsPerPageText: "Linhas por página:",
    ariaLabel: {
      sortDescending: "Ordenado decrescente.",
      sortAscending: "Ordenado crescente.",
      sortNone: "Não ordenado.",
      activateNone: "Ative para remover a ordenação.",
      activateDescending: "Ative para ordenar decrescente.",
      activateAscending: "Ative para ordenar crescente."
    },
    sortBy: "Ordenar por"
  },
  dataFooter: {
    itemsPerPageText: "Itens por página:",
    itemsPerPageAll: "Todos",
    nextPage: "Próxima página",
    prevPage: "Página anterior",
    firstPage: "Primeira página",
    lastPage: "Última página",
    pageText: "{0}-{1} de {2}"
  },
  datePicker: {
    itemsSelected: "{0} selecionado(s)",
    nextMonthAriaLabel: "Próximo mês",
    nextYearAriaLabel: "Próximo ano",
    prevMonthAriaLabel: "Mês anterior",
    prevYearAriaLabel: "Ano anterior"
  },
  noDataText: "Não há dados disponíveis",
  carousel: {
    prev: "Visão anterior",
    next: "Próxima visão",
    ariaLabel: {
      delimiter: "Slide {0} de {1} do carrossel"
    }
  },
  calendar: {
    moreEvents: "Mais {0}"
  },
  fileInput: {
    counter: "{0} arquivo(s)",
    counterSize: "{0} arquivo(s) ({1} no total)"
  },
  timePicker: {
    am: "AM",
    pm: "PM"
  },
  pagination: {
    ariaLabel: {
      wrapper: "Navegação de paginação",
      next: "Próxima página",
      previous: "Página anterior",
      page: "Ir à página {0}",
      currentPage: "Página atual, página {0}"
    }
  },
  rating: {
    ariaLabel: {
      icon: "Rating {0} of {1}"
    }
  }
};
