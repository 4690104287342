<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close"
    scrollable
    max-width="350px"
    transition="dialog-transition"
  >
    <v-card style="background-color:white;">
      <v-toolbar tile elevation="0">
        <v-toolbar-title>
          <div style="letter-spacing: 1px; font-weight: 700;">
            <span>{{ title }}</span>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="max-height: 500px;">
        <v-row class="pt-3">
          <h3 class="ml-2 mr-2 mt-2 mb-0">
            <div>
              <span v-html="msg"></span>
            </div>
            <br />
          </h3>
        </v-row>
        <v-row class="mt-6">
          <v-col cols="5" class="pa-0">
            <v-btn
              large
              rounded
              outlined
              width="100%"
              @click="close()"
              :loading="loading"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="1" class="pa-0" />
          <v-col cols="6" class="pa-0">
            <v-btn
              @click="continuar()"
              large
              color="primary"
              rounded
              width="100%"
              :loading="loading"
              >Continuar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialog: true,
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.dialog = false;
    },
    continuar() {
      this.loading = true;
      this.$emit("continuar");
      this.close();
    }
  }
};
</script>
