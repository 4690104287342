import axios from "axios";

export default {
  uploadMidia(formData) {
    return axios.post('/v1/marketplace/upload-midia-sem-produto', formData, {});
  },
  uploadMidias(formData) {
    return axios.post('/v1/marketplace/upload-midias-sem-produto', formData, {});
  },
  deleteCapa(payload){
    return axios.delete(`/v1/marketplace/${payload}/midia`)
  },
  getFiltros() {
    return axios.get("/v1/marketplace");
  },
  getMedida(){
    return axios.get("/v1/marketplace/unidade-medida");
  },
  getProducts(sortBy = "order"){
    return axios.get("/v1/marketplace/produto", {
      sortBy,
    })
  },
  getEstabelecimentos() {
    return axios.get("/v1/marketplace/estabelecimento");
  },
  salvar(produto) {
    return axios.post(`/v1/marketplace/produto`, produto);
  },
  salvarDesconto(produto) {
    return axios.post(`/v1/desconto`, produto);
  },
  salvarIngresso(payload){
    return axios.post(`/v1/ingresso`, payload)
  },
  getTipoPromocao(){
    return axios.get('/v1/tipos/tipo-desconto')
  },
  getRelatorios(){
    return axios.post('/v1/marketplace/relatorio',{
      descending: true,
      filtros: {

      },
      pageNo: 0,
      pageSize: 0,  
      sortBy: "id"
    })
  },
  getEvento(id){
    return axios.get(`/v1/marketplace/evento/${id}`)
  },
  salvarCodigos(_payload){
    return axios.post('/v1/desconto/codigos', _payload)
  },
  getCodigos(_id){
    return axios.get(`/v1/desconto/codigos/${_id}`)
  },
  getUsuariosCheckin(_id){
    return axios.get(`/v1/marketplace/usuario/${_id}`)
  },
  salvarUsuariosCheckin(_payload){
    return axios.post('/v1/marketplace/usuario', _payload)
  },
  getUsuariosRegistrados(_id){
    return axios.get(`/v1/marketplace/usuario-registrados/${_id}`)
  },
  getCategoriaSemVinculo(){
    return axios.get('/v1/marketplace/categoria-sem-vinculo')
  },
  copiarDesconto(_id,payload){
    return axios.post(`/v1/desconto/copia/${_id}`, payload)
  },
  copiarIngresso(_id){
    return axios.post(`/v1/ingresso/copia/${_id}`)
  },
  getSituacoes(){
    return axios.get('/v1/marketplace/situacao')
  }
};
