import axios from "axios";

export default {
  salvar(client) {
    if (client.id && client.id != 0) {
      return axios.post(`/v1/cliente/${client.id}`, client);
    }
    return axios.post(`/v1/cliente`, client);
  },

  updateDadosComplementares(payload) {
    return axios.put(`/v1/cliente/complementares`, payload);
  },

  getById(id) {
    return axios.get(`/v1/cliente/${id}`);
  },

  delete(id) {
    return axios.delete(`/v1/cliente/${id}`);
  },

  pagination(pageSize = 10, pageNo = 0, sortBy = "nome", filtros) {
    return axios.post("/v1/cliente/pagination", {
      pageSize,
      pageNo,
      sortBy,
      filtros
    });
  },

  getAllRestricted() {
    return axios.get('/v1/cliente/restricted');
  },

  uploadLogo(id, formData) {
    if (id && id != 0) {
      return axios.post(`/v1/cliente/${Number(id)}/upload-logo`, formData, {});
    }
    return axios.post(`/v1/cliente/upload-logo-sem-cliente`, formData, {});
  },

  getDefault() {
    return axios.get("/v1/cliente/default");
  },
  getUsuarioCupoms(id) {
    return axios.get(`/v1/cliente/${id}/usuarios/cupons`);
  }
};
