import axios from 'axios';

export default {
    getComissarios(id) {
      return axios.get(`/v1/comissario/nao-vinculados/${id}`);
    },
    getListagem(id) {
      return axios.get(`/v1/comissario/vinculados/${id}`);
    },
    salvarComissarioIngresso(_payload){
      return axios.post('/v1/comissario', _payload)
    },
    deleteUser(_id){
      return axios.delete(`/v1/comissario/${_id}`)
    },
    getComissarioItems(){
      return axios.get('/v1/comissario/pagination')
    },
}

