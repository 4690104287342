import axios from "axios";

export default {
  getAll() {
    return axios.get(`/v1/tipos/planos`);
  },

  getAllPlanos() {
    return axios.get(`/v1/plano`);
  },

  getAllPlanosEstabelecimento() {
    return axios.get(`/v1/plano/estabelecimento`);
  },

  getValorConteudoExclusivo() {
    return axios.get(`/v1/plano/valor-plano/valor-conteudo-exclusivo`);
  },

  getValorDefaultGorjeta() {
    return axios.get(`/v1/plano/valor-plano/valor-default-gorjeta`);
  },

  saveValorPlano(desconto, total, valor, valorPlanoId) {
    let payload = {};
    if (desconto) {
      payload.desconto = desconto;
    }
    if(!desconto){
      payload.desconto = 0
    }
    if (total) {
      payload.total = total;
    }
    if (valor) {
      payload.valor = valor;
    }
    if(!valor){
      payload.valor = payload.total
    }
    payload.valorPlanoId = valorPlanoId;
    return axios.put(`/v1/plano/valor-plano`, payload);
  }
};
