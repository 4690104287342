import axios from "axios";

export default {
  pagination(pageSize = 10, pageNo = 0, sortBy = "nome", filtros) {
    return axios.post("/v1/usuarioexterno/pagination", {
      pageSize,
      pageNo,
      sortBy,
      filtros
    });
  },

  bloquear(id) {
    return axios.put("/v1/usuarioexterno/bloquear/" + id);
  },

  desbloquear(id) {
    return axios.put("/v1/usuarioexterno/desbloquear/" + id);
  },

  getByEmail(_email) {
    return axios.post("/v1/usuarioexterno/email", {
      email: _email
    });
  },

  newUsuarioExterno(_payload){
    return axios.post("/v1/usuarioexterno/cadastro", {
      email: _payload.email,
      nome: _payload.email.split('@')[0],
      clienteId: _payload.clienteId
    })
  },
  
  newUsuarioVinculado(_payload){
    return axios.post("/v1/usuarioexterno/vinculado/cadastro", {
      email: _payload.email,
      nome: _payload.nome,
      clienteId: _payload.clienteId
    })
  },
  
  getUsuarioVinculado(){
    return axios.get("/v1/usuarioexterno/vinculado")
  },

  blockUsuarioVinculado(_payload){
    return axios.post("/v1/usuarioexterno/vinculado/desabilita", {
      email: _payload.email,
      nome: _payload.nome,
    })
  },
};
