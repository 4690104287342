export default {
  getCidades(state) {
    return state.cidades;
  },
  getClientes(state) {
    return state.clientes;
  },
  getSituacoesPublicacao(state) {
    return state.situacoesPublicacao;
  },
  getTiposPublicacao(state) {
    return state.tiposPublicacao;
  },
  getEstados(state) {
    return state.estados;
  },
  getTipoMensagens(state) {
    return state.tipoMensagens;
  },
};
