<template>
  <div class="mx-auto">
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-avatar v-else :size="large ? '100px' : '36px'">
      <v-img v-if="avatarUrl" :src="avatarUrl"> </v-img>
      <v-icon
        :color="isInfluencers() ? 'ice' : ''"
        v-else
        :size="large ? '100px' : '36px'"
      >
        mdi-account-circle
      </v-icon>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    avatarUrl: {
      type: String,
      required: false,
      default: ""
    },
    large: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
