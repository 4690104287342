export default {
  setProdutos(state, payload) {
    state.produtos = payload;
  },
  setEditProduto(state, payload) {
    state.editProduto = payload;
  },
  setProdutosRelatorio(state, payload){
    state.produtosRelatorio = payload
  }
};
