import axios from "axios";

export default {
  defaultMensagem() {
    return {
      id: null,
      dataPublicacao: null,
      dataValidade: null,
      titulo: "",
      descricao: "",
      tipoMensagemId: null
    };
  },

  pagination(pageSize = 10, pageNo = 0, sortBy = "nome", filtros) {
    return axios.post("/v1/mensagem/pagination", {
      pageSize,
      pageNo,
      sortBy,
      filtros
    });
  },

  salvar(mensagem) {
    return axios.post(`/v1/mensagem/save`, mensagem);
  },

  delete(id) {
    return axios.delete(`/v1/mensagem/delete/${id}`);
  }
};
