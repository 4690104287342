export default {
  setCidades(state, payload) {
    state.cidades = payload;
  },
  setClientes(state, payload) {
    state.clientes = payload;
  },
  setSituacoesPublicacao(state, payload) {
    state.situacoesPublicacao = payload;
  },
  setTiposPublicacao(state, payload) {
    state.tiposPublicacao = payload;
  },
  setEstados(state, payload) {
    state.estados = payload;
  },
  setTipoMensagens(state, payload) {
    state.tipoMensagens = payload;
  }
};
