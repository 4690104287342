<template>
  <v-row justify="center">
    <v-dialog value="true" persistent max-width="700px">
      <v-card>
        <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
          <v-toolbar-title>
            <span class="headline">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="closeDialog">mdi-close</v-icon>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="salvar"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <avatar
                    large
                    :avatarUrl="usuario.fotoUrl"
                    :loading="loadingFoto"
                  />
                  <br />
                  <v-form enctype="multipart/form-data">
                    <label
                      for="foto"
                      style="cursor: pointer"
                      class="caption"
                      title="avatar"
                    >
                      {{ formData ? formData.get("name") : "Troque o avatar" }}
                      <v-icon color="dark" for="foto">mdi-camera</v-icon>
                      <input
                        id="foto"
                        style="display: none"
                        type="file"
                        @change="uploadAvatar($event)"
                      />
                    </label>
                  </v-form>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="usuario.nome"
                    label="Nome*"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-select
                    v-model="usuario.roleId"
                    :items="roles"
                    item-text="nome"
                    item-value="id"
                    label="Perfil de Acesso*"
                    required
                    :rules="[rules.required]"
                    :loading="loading"
                    :disabled="
                      disabledRole ||
                        (usuario.id &&
                          (usuario.roleId == 4 || usuario.roleId == 5))
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="isPerfilAdminOrParceiro">
                  <v-select
                    v-model="usuario.clientes"
                    :items="clientes"
                    item-text="descricao"
                    item-value="id"
                    label="Estabelecimentos*"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                    required
                    :rules="[rules.notEmpty]"
                    :loading="loading"
                    :disabled="disabledRole"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="usuario.email"
                    label="Email*"
                    :rules="[rules.required, rules.email]"
                    hint="esse email será utilizado para entrar no sistema"
                    required
                    :disabled="usuario.id ? true : false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <template v-if="usuario.id">
                    <v-btn
                      v-if="!flgAlterarSenha"
                      @click="flgAlterarSenha = true"
                    >
                      Alterar Senha
                    </v-btn>
                    <template v-else>
                      <v-text-field
                        v-model="usuario.senha"
                        label="Senha*"
                        type="password"
                        required
                        :rules="[rules.required]"
                      />
                      <v-btn :loading="loading" @click="alterarSenha">
                        Salvar Senha
                      </v-btn>
                    </template>
                  </template>
                  <template v-else>
                    <v-text-field
                      v-model="usuario.senha"
                      label="Senha*"
                      type="password"
                      required
                      :rules="[rules.required]"
                    />
                  </template>
                </v-col>
              </v-row>
            </v-container>
            <small class="error--text">*campos obrigatórios</small>
          </v-card-text>
          <v-card-actions>
            <v-switch
              v-if="!isUsuarioAtivo"
              :label="usuario.isAtivo ? 'Ativo' : 'Inativo'"
              class="ma-0 ml-4"
              v-model="usuario.isAtivo"
              inset
            ></v-switch>
            <v-spacer></v-spacer>
            <v-btn color="btnCancelar" text @click="closeDialog">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              type="submit"
              :loading="loadingSalvar"
            >
              <span class="text-capitalize">Salvar</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Avatar from "./avatar.vue";
import { RoleService, ClientService, UserService } from "../services";
import { Role } from "../constants";
export default {
  components: { Avatar },
  props: {
    callback: {
      type: Function,
    },
    closeDialog: {
      type: Function,
    },
    usuario: {
      type: Object,
      default: () => ({
        nome: "",
        email: "",
        roleId: "",
        roleLabel: "",
        fotoUrl: "",
        senha: "",
        clientes: [],
      }),
    },
    disabledRole: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: false,
    roles: [],
    dialogSenha: false,
    editedIndex: -1,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      notEmpty: (value) =>
        (!!value && value.length > 0) || "Campo obrigatório.",
      confirm: (value) =>
        (!!value && this.novaSenha === value) ||
        "As duas senhas precisam ser iguais!",
      confirmDelete: (value) =>
        (!!value && this.usuario.email === value) ||
        "O endereço de email precisa ser igual!",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      },
    },
    loadingFoto: false,
    loadingSalvar: false,
    loading: false,
    formData: null,
    file: {},
    flgAlterarSenha: false,
    clientes: [],
  }),
  computed: {
    formTitle() {
      return this.usuario.id ? "Alterar Usuário" : "Novo Usuário";
    },
    isPerfilAdminOrParceiro() {
      return (
        this.usuario.roleId == Role.ADMIN_PARCEIRO ||
        this.usuario.roleId == Role.USER_PARCEIRO ||
        this.usuario.roleId == Role.COMISSARIO
      );
    },
    isUsuarioAtivo() {
      return this.$store.getters["user/getUser"].id == this.usuario.id;
    },
  },
  watch: {
    isPerfilAdminOrParceiro(val) {
      if (val) {
        this.getClientes();
      }
    },
  },
  beforeMount() {
    if (this.isPerfilAdminOrParceiro) {
      this.getClientes();
    }

    this.getRoles();
  },
  methods: {
    salvar() {
      console.log(this.usuario);
      if (this.$refs.form.validate()) {
        this.loadingSalvar = true;

        UserService.salvar(this.usuario)
          .then((response) => {
            this.$root.showSucesso(response.data);
            this.callback();
          })
          .catch((error) => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Todos os campos são obrigatórios");
      }
    },
    uploadAvatar(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);

        this.loadingFoto = true;
        UserService.uploadFoto(this.usuario.id, this.formData)
          .then((response) => {
            this.usuario.fotoUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a foto do perfil, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingFoto = false;
          });
      }
    },
    alterarSenha() {
      this.loading = true;
      UserService.updateSenha(this.usuario)
        .then((response) => {
          this.$root.showSucesso(response.data);
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
          this.flgAlterarSenha = false;
        });
    },
    getRoles() {
      if (!this.roles || this.roles.length == 0) {
        this.loading = true;
        let roleFunction = this.disabledRole
          ? RoleService.getAllRoles
          : RoleService.getRestrictedRoles;
        roleFunction()
          .then((response) => {
            this.roles = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getClientes() {
      if (!this.clientes || this.clientes.length == 0) {
        this.loading = true;
        ClientService.getAllRestricted()
          .then((response) => {
            this.clientes = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
